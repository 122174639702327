// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import chat from '@src/views/apps/chat/store/reducer'
import todo from '@src/views/apps/todo/store/reducer'
import users from '@src/views/apps/user/store/reducer'
import customers from '@src/views/apps/customer/store/reducer'
import accounts from '@src/views/accounts/store/reducer'
import email from '@src/views/apps/email/store/reducer'
import invoice from '@src/views/apps/invoice/store/reducer'
import tasks from '@src/views/tasks/store/reducer'
import calendar from '@src/views/apps/calendar/store/reducer'
import ecommerce from '@src/views/apps/ecommerce/store/reducer'
import dataTables from '@src/views/tables/data-tables/store/reducer'
import adminDashboard from '@src/views/dashboard/admin/store/reducer'
import superDashboard from '@src/views/dashboard/super/store/reducer'
import forms from '@src/views/forms/store/reducer'
import odm from '@src/views/odm/store/reducer'

const rootReducer = combineReducers({
  auth,
  todo,
  chat,
  email,
  users,
  customers,
  accounts,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  tasks,
  dataTables,
  adminDashboard,
  superDashboard,
  forms,
  odm
})

export default rootReducer
